import React, { useContext } from 'react'
import { Route, Routes } from 'react-router-dom';
import Home from './Components/Home';
import SideBar from './Components/SideBar';
import Cat from './Components/Cat';
import Subcat from './Components/Subcat';
import { MyContext } from './Context/MyContext';
import Login from './Components/Login';
import Post from './Components/Post';
import "./App.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const App = () => {
  const { isLoggedIn } = useContext(MyContext);
  return (
    <>

   <ToastContainer/>
    <div className='row'>
      {
        isLoggedIn==true?<div className='col-2'>
        <SideBar/>
      </div>:null
      }

      <div className='col-10'>
      <Routes>
      <Route exact path='/Login' Component={Login}/>
      <Route exact path='/Home' Component={Home}/>
      <Route exact path='/Cat' Component={Cat}/>
      <Route exact path='/SubCat' Component={Subcat}/>
      <Route exact path='/Post' Component={Post}/>
    </Routes>
     
      
</div>
</div>
  
    </>
  )
}

export default App;

// import React, { useState } from "react";
// import { convertFromRaw, EditorState } from "draft-js";
// import draftToHtml from "draftjs-to-html";
// import { Editor } from "react-draft-wysiwyg";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";



// const App = () => {
//   // Array of editor states and content states
//   const [editors, setEditors] = useState([
//   ]);

//   const handleEditorStateChange = (editorState, index) => {
//     const updatedEditors = editors.map((editor, i) => 
//       i === index ? { ...editor, editorState } : editor
//     );
//     setEditors(updatedEditors);
//   };

//   const handleContentStateChange = (contentState, index) => {
//     const htmlContent = draftToHtml(contentState);
//     const updatedEditors = editors.map((editor, i) => 
//       i === index ? { ...editor, contentState: htmlContent } : editor
//     );
//     setEditors(updatedEditors);
//   };
//   const addEditor = () => {
//     setEditors([...editors, { editorState: EditorState.createEmpty(), contentState: "" }]);
//   };

//   return (
//     <div className="App">

      
//       <h2 onClick={()=>{addEditor()}}>Output </h2>



//       {editors.map((editor, index) => (
//         <div key={index} className="editor-container">
//           <Editor
//             editorState={editor.editorState}
//             toolbarClassName="editor-toolbar"
//             wrapperClassName="editor-wrapper"
//             editorClassName="editor"
//             onEditorStateChange={(state) => handleEditorStateChange(state, index)}
//             onContentStateChange={(content) => handleContentStateChange(content, index)}
            
//           />

//           <h2 onClick={()=>{addEditor()}}>Output {index + 1}</h2>
//           <pre>
//             <code>{editor.contentState}</code>
//           </pre>

//           <h2>Rendered {index + 1}</h2>
//           <div dangerouslySetInnerHTML={{ __html: editor.contentState }} />
//         </div>
//       ))}
//     </div>
//   );
// };

// export default App;
